const [igw,igh] = [1000,1000]
const [tw,th] = [1200,700]
const [lw,lh] = [2550,3300]

const logoAspect = .33628;
const logo = height => ({
  pos: [0,height - (igw*.15)*logoAspect],
  size: [igw*.15,(igw*.15)*logoAspect]
})

export default {
  instagram: {
    size: [igw,igh],
    tapestry: {
      variant: "horizontal",
      illustration: {
        size: [igw,igh/2],
        pos: [0,0]
      },
      text: {
        size: [igw,igh/2],
        pos: [0,igh/2]
      },
      logo: logo(igh)
    },
    icon: {
      illustration: {
        size: [igw/2,igh/2],
        pos: [igw/2,igh/2]
      },
      text: {
        size: [igw,igh/2],
        pos: [0,0]
      },
      logo: logo(igh)
    }
  },
  twitter: {
    size: [tw,th],
    tapestry: {
      variant: "vertical",
      illustration: {
        size: [th/2,th],
        pos: [tw - th/2, 0]
      },
      text: {
        size: [tw - th/2, th],
        pos: [0,0]
      },
      logo: logo(th)
    },
    icon: {
      illustration: {
        size: [th*.66,th*.66],
        pos: [tw - th*.66, th*.34]
      },
      text: {
        size: [tw - th/2, th],
        pos: [0,0]
      },
      logo: logo(th)
    }
  },
  letter: {
    size: [lw,lh],
    fontSize: 300,
    tapestry: {
      variant: "horizontal",
      illustration: {
        size: [lw,lw/2],
        pos: [0, 0]
      },
      text: {
        size: [lw, lh - lw/2],
        pos: [0,lw/2]
      },
      logo: {
        pos: [0, lh - (lw*.15)*logoAspect],
        size: [lw*.15,(lw*.15)*logoAspect]
      }
    },
    icon: {
      illustration: {
        size: [lh/2,lh/2],
        pos: [lw - lh/2, lh/2]
      },
      text: {
        size: [lw, lh*.33],
        pos: [0,0]
      },
      logo: {
        pos: [0, lh - (lw*.15)*logoAspect],
        size: [lw*.15,(lw*.15)*logoAspect]
      }
    }
  }
}