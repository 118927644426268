import React, { useEffect, useState } from 'react';
import './App.scss';
import Nav from "components/Nav";
import Landing from "components/Landing";
import { allQuery } from "queries"

function App() {
  const landingData = useData(allQuery)
  return (
    <div className="App">
      <Nav />
      {landingData
        ? (landingData.errors ? "Error" : <Landing {...landingData.data} />)
        : <div className={"AppLoader"}>Loading...</div>
      }
    </div>
  );
}

export default App;


const useData = (query) => {
  const [data,set] = useState()
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query
      })
    })
      .then(res => res.json())
      .then(res => { set(res) })
  },[query])
  return data
}