import React, { useReducer, useState } from "react";
import { Canvas, getSrc } from "components/Graphics";
import { ReactComponent as X } from "svg/x.svg";
import Modal from "components/Modal";
import "./EditGraphic.scss";

import { splitMessage } from "./helpers";

import sizes from "sizes";

const reducer = (state,action) => {
  switch (action.type) {
    case "setMessage":
      return {
        ...state,
        message: {
          typeHandle: "text",
          text: action.value
        }
      }

    case "setColor":
      return {
        ...state,
        color: action.value
      }

    case "setImage":
      return {
        ...state,
        image: action.value
      }

    case "setSize":
      return {
        ...state,
        size: action.value
      }

    case "setDownload":
      return {
        ...state,
        download: action.value
      }

    default:
      throw new Error(`Unknown action type ${action.type}`)

  }
}

export default ({ type, message, image, defaultColorCombo, setEdit, pieces, colors }) => {

  const initialState = {
    message,
    image,
    color: defaultColorCombo,
    size: 'instagram',
    download: null
  }
  const [state,dispatch] = useReducer(reducer,initialState)

  const piece = pieces.find(d => {
    return d.id === state.image
  })

  const close = () => {
    setEdit(null)
  }

  return <Modal close={close}>
    <div className="EditGraphic-inner-grid">
      <div className={"EditGraphic-result"}>
        <Canvas
          message={state.message}
          color={colors[state.color]}
          editDispatch={dispatch}
          piece={piece}
          size={state.size}
          type={piece.typeHandle} />
        <div className="EditGraphic-submit">
          <a className="EditGraphic-download" href={state.download} download={"DonorsChoose.jpg"}>Download</a>
        </div>
      </div>
      <div className="Modal-inner-close">
        <button className="Modal-inner-close-button" onClick={close}>
          <X />
        </button>
      </div>
      <div className="EditGraphic-controls">
        <form>
          {message.typeHandle === "text" && <Message
            text={message.text}
            setText={value => {
              dispatch({
                type: "setMessage",
                value
              })
            }} />}
          {type === "tapestry" && <Colors
            colors={colors}
            current={state.color}
            set={value => {
              dispatch({
                type: "setColor",
                value
              })
            }} />}
          <Illustrations
            colors={colors}
            pieces={pieces.filter(d => d.typeHandle === type)}
            current={state.image}
            setColor={value => {
              dispatch({
                type: "setColor",
                value
              })
            }}
            set={value => {
              dispatch({
                type: "setImage",
                value
              })
            }} />
          <Sizes
            sizes={sizes}
            current={state.size}
            set={value => {
              dispatch({
                type: "setSize",
                value
              })
            }} />
        </form>
      </div>
    </div>
  </Modal>
}

const messageInputLimit = 30
const Message = ({ text, setText }) => {
  const matches = splitMessage(text)
  const [value,set] = useState("")
  if (!matches) { return null }
  return <div className="Message">
    <label>
      <div className="EditGraphic-control-label">{matches[2]}</div>
      <div className="Message-input">
        <input
          autoFocus
          type="text"
          value={value}
          maxLength={messageInputLimit}
          onChange={event => {
            set(event.target.value)
            setText(event.target.value.length ? `${matches[1]}${event.target.value}${matches[3]}` : text)
          }} />
        <div className={"Message-count"}>{`${value.length}/${messageInputLimit}`}</div>
      </div>
    </label>
  </div>
}

const Colors = ({ colors, current, set }) => {
  return <div className="Colors">
    <div className="EditGraphic-control-label">Color Combination</div>
    <div className="ColorChoices">
      {Object.keys(colors).map(color => (
        <label className="ColorChoices-item" key={color}>
          <input type="radio"
            name="color"
            checked={current === color}
            onChange={() => {
              set(color)
            }} />
          <div className="ColorChoice-label">
            <div style={{
              backgroundColor: colors[color].bg,
              color: colors[color].text
            }}>A</div>
          </div>
        </label>
      ))}
    </div>
  </div>
}

const Illustrations = ({ pieces, current, set, setColor, colors }) => {
  return <div className="Illustration">
    <div className="EditGraphic-control-label">Choose Illustration</div>
    <div className={`Illustration-choices Illustration-choices--${pieces[0].typeHandle}`}>{pieces.map(piece => {
      const colorCombo = piece.defaultColorCombo && piece.defaultColorCombo[0] ? piece.defaultColorCombo[0].id : null
      return (
        <label key={piece.id}
          className={`Illustration-choices-item Illustration-choices-item--${piece.typeHandle}`}>
          <input type="radio"
            name="illustration"
            checked={current===piece.id }
            onChange={() => {
              set(piece.id)
              if (colorCombo) {
                setColor(colorCombo)
              }
            }} />
          <div className={"Illustration-choices-item-label"}style={{
            backgroundColor: colorCombo ? colors[colorCombo].bg : null
          }}>
            <img src={getSrc(piece,piece.typeHandle,"instagram")} alt="" />
          </div>
        </label>
      )
    })}</div>
  </div>
}

export const Sizes = ({ sizes, current, set }) => {
  return <div className="Sizes">
    <div className="EditGraphic-control-label">Select Size</div>
    <div className="Sizes-choices">{Object.keys(sizes).map(key => {
      const {size} = sizes[key]
      return <label key={key} className="Sizes-choices-item">
        <input type="radio"
          name="Sizes"
          checked={current===key }
          onChange={() => {
            set(key)
          }} />
        <div className={"Size-label"}>
          <div className={"Size-label-image"}>
            <svg viewBox={`0 0 ${size[0]} ${size[1]}`}>
              <rect width={size[0]} height={size[1]} fill={"#dadada"} />
            </svg>
          </div>

        </div>
        <div className={"Size-label-text"}>{key}</div>
      </label>
    })}</div>
  </div>
}